<template>
  <b-card class="border border-primary">
    <v-jstree
      :data="items"
      item-key="title"
      show-checkbox
      multiple
      allow-batch
      whole-row
      :draggable="false"
    />
  </b-card>
</template>

<script>
import VJstree from 'vue-jstree';
import { permissions as permissionLookups } from '@/libs/acl/Lookups';

export default {
  props: {
    userPermissions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VJstree,
    // VTreeview,
  },
  data() {
    return {
      active: [],
      items: [],
      permissionLookups,
    };
  },
  // mounted() {
  //   this.items = this.permissionLookups.filter((p) => p).map((permission) => {
  //     return {
  //       text: this.$t(`${permission.title}`),
  //       selected: this.userPermissions.some(
  //           (v) => permission.name === v.permissionName
  //         ),
  //       title: permission.name,
  //       name: permission.name,
  //       id: permission.name,
  //     };
  //   });
  //   this.$emit('update-items', this.items);
  // },
  mounted() {
    this.items = this.permissionLookups.filter((p) => p.roles).map((permission) => {
      const childrens = permission.roles.map((role) => {
        return {
          text: this.isRight ? role.arabicName : role.englishName,
          id: role.id,
          type: role.type,
          title: role.id,
          selected: this.userPermissions.some(
            (v) => role.id === v.permissionId
          ),
        };
      });
      return {
        children: childrens,
        opened: false,
        type: permission.type,
        selected:
          childrens.filter((c) => c.selected).length,
        text: this.$t(`${permission.title}`),
        title: permission.name,
        id: permission.name,
      };
    });
    this.$emit('update-items', this.items);
  },
  methods: {
    toggleSelect() {
      this.items.forEach(element => {
        element.selected = this.selectAll
        element.children.forEach(item => {
          item.selected = this.selectAll
        });
      });
    },
  }
};
</script>

<style></style>
